.direction-container {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  width: max-content;
  img {
    max-width: 1rem;
  }
}
