.trace-property {
  &-details {
    word-break: break-word;
  }
  &-label {
    font-weight: bold;
  }
  .attachment-received {
    display: inline;
  }
  .attachment-link {
    display: inline-flex;
  }
}
