@import "../../../../../sass/Variables.scss";

.coordinate-container {
  display: flex;
  height: 1.5rem;
  flex-direction: row;
  align-items: center;
  width: max-content;
  img {
    max-width: 1rem;
  }
}

.copy {
  cursor: pointer;
  margin-left: 0.4rem;
  margin-bottom: 0.175rem;
  &-message {
    display: flex;
    padding: 0.125rem;
    font-size: 0.7rem;
    margin-left: 0.75rem;
    border-radius: 0.188rem;
  }
  &.active,
  &:hover {
    svg {
      path {
        &.fillColor {
          fill: $blue-1;
        }
      }
    }
  }
}

.popup-toast {
  font-size: 0.7rem;
  margin-left: 0.45rem;
  border: none !important;
  color: $bg-white !important;
  box-shadow: none !important;
  position: relative !important;
  padding: 0.0625rem 0.3rem 0.0625rem 0.15rem;
  &-success {
    color: $blue-1 !important;
    border-color: $blue-1 !important;
    background-color: $bg-white !important ;
  }
}

.check-icon {
  margin: 0 0.0625rem;
}
