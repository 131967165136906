@import "../../../../../sass/Variables.scss";

.timeline-container {
  height: 100%;
  width: 100%;
  background-color: $gray-100;
}

.timeline-summary {
  display: flex;
  justify-content: space-between;
  padding: 0.063rem 0.5rem;
  height: 30%;
  overflow: auto;
}
.timeline-content {
  height: 70%;
}
.timeline-error {
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
}
.timeline-error-icon {
  height: 0.75rem;
  width: 0.75rem;
  margin: 0.33rem 0.2rem;
}
