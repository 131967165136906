@import "../../../../sass/Variables.scss";
.toast {
  bottom: 7% !important;
  border-radius: 0.313rem !important;
  &-message {
    color: $black;
    padding: 0 0.8rem;
  }
  &-close {
    margin-bottom: 0.1rem;
    color: $black;
    &:hover {
      color: $black !important;
    }
  }
}

.close {
  text-shadow: none !important;
}
