@import "../../../sass/Variables.scss";

.card {
  &-container {
    display: flex;
    flex: 0.475;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $gray-100;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    box-shadow: $gray-100 0rem 0.075rem;
  }
  &-title {
    max-width: 8rem;
    margin: 0rem;
  }
  &-value {
    font-weight: bold;
    margin-top: 0.25rem;
    max-width: 8rem;
  }
}
