@import "../../../../sass/Variables.scss";

.two-row-container {
  height: 100%;
  width: 100%;
}

.h-30 {
  height: 30% !important;
}

.h-70 {
  height: 70% !important;
}
