@import "../../../sass/Variables.scss";

.msg {
  &-container {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    padding: 0.75rem 0.25rem;
  }
  &-subject {
    top: -0.875rem;
    display: flex;
    padding: 0.1rem 0.75rem;
    align-self: center;
    position: absolute;
    border-radius: 0.35rem;
    background-color: $gray-50;
    border: 0.1rem solid $gray-200;
    font-size: 0.7rem;
    max-width: 70%;
  }
}

.error-msg-toast {
  position: fixed;
  bottom: 3rem;
  left: 30%;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}

.arrow {
  margin-left: 0.5rem;
  transition: transform 0.3s ease-in-out;
  &-up {
    transform: rotate(0deg);
  }
  &-down {
    transform: rotate(-180deg);
  }
}

@media only screen and (max-width: 991.98px) {
  .msg-subject-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .error-msg-toast {
    left: 8%;
  }
}

@media only screen and (min-width: 992px) {
  .msg-subject-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
