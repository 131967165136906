@import "../../../sass/Variables.scss";

.task {
  &-delay {
    color: $warning;
  }
  &-early {
    color: $active;
  }
  &-arrivalTime {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.75rem;
    font-weight: 700;
  }
}
