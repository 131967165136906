@import "../../../../../sass/Variables.scss";

.trace-marker {
  fill: $blue-dark;
  &-selected,
  &-highlight {
    z-index: 1 !important;
    svg {
      animation: pulse 0.8s ease-in-out;
      g {
        fill: $blue-200;
      }
    }
  }
}
.traces-map {
  .trace-marker-fadeIn {
    animation: fadeIn 0.2s;
  }

  .trace-marker-fadeOut {
    animation: fadeOut 0.2s;
  }

  .hide-marker {
    opacity: 0;
  }
}

.trimblemaps-marker {
  cursor: pointer;
}
.trimblemaps-popup {
  z-index: 2;
}
.trimblemaps-popup-content {
  .trace-details-container,
  .traceview-info-container {
    min-width: 13rem;
  }
}
.trace-marker-selected {
  .marker-highlight {
    position: absolute;
    top: -0.05rem;
    left: -0.25rem;
    z-index: -1;
    width: 1.5rem;
    height: 1.5rem;
  }
}

#map {
  .trimblemaps-ctrl-top-right {
    div {
      &:first-child {
        position: relative;
        top: 3rem;
      }
      &:not(:first-child) {
        position: absolute;
        top: 0;
      }
    }
  }
  .trimblemaps-popup {
    top: -0.375rem;
  }
  .map-style-layer-icon {
    top: 10.65rem;
    margin-right: 0.65rem;
    path:not(:first-child) {
      fill: $blue-100;
    }
  }
  .map-style-layer-container {
    right: 3.5rem;
  }
}
.traces-map-desktop-container {
  .map-style-layer-container {
    top: 10.65rem;
  }
}
.trace-map-container-mob {
  .map-menu {
    top: 0;
    position: relative;
    path {
      fill: $blue-100;
    }
  }
  .map-style-layer-container {
    top: 5.5rem;
  }
  .map-menu-icon {
    margin: 0 0 0.85rem 0;
  }
}
.traces-map-container {
  position: absolute;
  z-index: 2;
  display: flex;
  top: 0.6rem;
  left: 14.5rem;
}
@media only screen and (max-width: 991.98px) {
  .traces-map-container {
    flex-direction: column;
    top: 16.25rem;
    right: 0.5rem;
    left: unset;
  }
}
