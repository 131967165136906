@import "../../../sass/Variables.scss";
.conversation-thread {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 0.1rem solid $gray-200;
    border-radius: 0.5rem;
    padding: 1rem 1rem 0.2rem;
    font-size: 0.875rem;
    margin: 0.75rem 0rem;
  }
  &-collapse {
    color: $black;
  }
}
