@import "../../../../sass/Variables.scss";

.clear {
  width: 50%;
  margin: auto;
  padding: 0 0 0.25rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9 !important;
}
