@import "../../../sass/Variables.scss";

.badge-icon {
  cursor: pointer;
}
.badge {
  position: absolute;
  margin-left: -0.65rem;
  margin-top: -0.2rem;
  padding: 0;
  .badge-value {
    padding: 0.23rem;
    min-width: 0.875rem;
    height: 0.875rem;
    font-size: 0.6rem;
    line-height: 0.6;
  }
}
