@import "../../../../sass/Variables.scss";

.back-icon {
  cursor: pointer;
  position: absolute;
  top: 0.35rem;
  left: 0.35rem;
  width: 0.65rem;
  height: 0.65rem;
  &:hover {
    background-color: $gray-150;
  }
}
