@import "../../../sass/Variables.scss";

.msg {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  white-space: pre-line;
  width: fit-content;
  flex-direction: column;
  position: relative;
  &-info {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0rem 0.25rem;
    color: $gray-400;
  }
  &-time {
    margin-left: 1rem;
    font-size: 0.688rem;
    align-self: flex-end;
    display: flex;
  }
  &-unread {
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 50%;
    margin: 0rem 0.25rem;
    background-color: $col_red;
  }
  .translate-option {
    cursor: pointer;
    text-decoration: underline;
  }
}

.msg-transition:last-child {
  animation: slideFromBottom 0.25s;
}

.msg-sent {
  display: flex;
  max-width: 85% !important;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  .msg-text {
    display: flex;
    padding: 0.4rem;
    margin: 0.2rem;
    border-radius: 0.35rem;
    background-color: $col_blue_pale;
  }
  .msg-tip {
    &::after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0rem;
      right: -0.63rem;
      border-right: 1rem solid transparent;
      border-bottom: 0.63rem solid $col_blue_pale;
    }
  }
  &-sent {
    align-self: flex-end;
    background-color: $col_blue_pale;
    &::after {
      content: "";
      height: 100%;
      position: absolute;
      border-right: 1rem solid transparent;
      border-bottom: 0.63rem solid $col_blue_pale;
      right: -0.63rem;
    }
  }
}

.msg-received {
  max-width: 85% !important;
  .msg-text {
    display: flex;
    margin: 0.2rem;
    padding: 0.4rem;
    border-radius: 0.35rem;
    background-color: $gray-200;
  }
  .msg-tip {
    &::before {
      content: "";
      height: 100%;
      position: absolute;
      border-left: 1rem solid transparent;
      border-bottom: 0.63rem solid $gray-200;
      left: -0.63rem;
      bottom: 0rem;
    }
  }
  .attachment-toast {
    bottom: -30%;
  }
  .attachment-link {
    display: inline-flex;
    margin-left: 0.25rem;
    min-width: 70%;
  }
}

.message {
  &-container {
    max-width: 100%;
  }
  &-text {
    word-break: break-word;
  }
}
.dot {
  height: 0.188rem;
  width: 0.188rem;
  border-radius: 50%;
  margin: 0rem 0.25rem;
  margin-top: 0.1rem;
  background-color: $col_gray_6;
}
//Mobile screen
@media only screen and (max-width: 991.98px) {
  .msg-sent {
    .attachment-toast {
      min-width: 9rem;
      left: -20%;
    }
  }
}

//Desktop screen
@media only screen and (min-width: 992px) {
  .msg-sent {
    .attachment-toast {
      left: -55%;
    }
  }
  .msg-received {
    .attachment-toast {
      min-width: 16rem;
    }
  }
}
