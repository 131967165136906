@import "../../../sass/Variables.scss";

.strike-through {
  &-container {
    display: flex;
    flex: 0.475;
    position: relative;
    padding: 0.313rem;
    flex-direction: column;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-title {
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.1rem solid $col_gray_8;
    border-radius: 50%;
    margin: 0 0.5rem;
    background-color: $col_green_pale;
  }
  &-finished {
    background-color: $col_gray_1;
  }
}
.strike-line {
  opacity: 0.85;
  position: absolute;
  height: 1.45rem;
  border: 1px solid $gray-400;
  background-color: $col_gray_1;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
