@import "../../../../../sass/Variables.scss";

.traces-map-desktop-container {
  height: 100% !important;
  width: 100% !important;
}

.datepicker-wrapper {
  position: absolute !important;
  cursor: pointer;
  z-index: 1;
  top: 0.6rem;
  left: 0.625rem;
  box-shadow: $box-shadow;
  background-color: $col_white;
  border-radius: $border-radius;
}
