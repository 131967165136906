@import "../../../sass/Variables.scss";

.dispatch {
  &-label {
    font-weight: 600;
  }
  &-title {
    color: $blue-1;
  }
}

.textArea {
  resize: none !important;
  height: 5rem !important;
}

.mandate-field {
  padding-left: 0.25rem;
  color: $danger;
}
.dispatch-toast {
  .toast {
    position: relative !important;
    padding: 0.1rem 0.65rem;
    font-size: 0.7rem;
  }
}
