.task-description {
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
  margin-top: 0.1rem;
  cursor: text;
}

.line-clamp-4 {
  line-clamp: 4;
  -webkit-line-clamp: 4;
}
