.speed-container {
  display: flex;
  align-items: center;
  img {
    max-width: 1rem;
  }
}
.speed-value {
  margin-left: 0.33rem;
}
