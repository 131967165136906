@import "../../../sass/Variables.scss";

.conversation {
  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 0.0063rem $gray-200;
  }
  &-vehicle {
    max-width: 80%;
    display: flex;
    flex: 1;
    height: 2rem;
    align-items: center;
    font-size: 0.875rem;
    background-color: $gray-100;
    margin-right: 0.75rem;
    border-radius: 0.15rem;
    .title-block {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .vehicle-title {
      margin: 0 0.3rem;
      max-width: 70%;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
}

.msg {
  &-back-btn {
    margin-right: 0.75rem;
    cursor: pointer;
  }
}
@media only screen and (max-width: 991.98px) {
  .conversation {
    &-header {
      padding: 0.875rem;
      max-width: calc(100vw - 3.5rem);
    }
  }
}

@media only screen and (min-width: 992px) {
  .conversation {
    &-header {
      padding: 1rem 6rem;
      max-width: calc(100vw - 28rem);
    }
  }
}
