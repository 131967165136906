@import "./../../../sass/Variables.scss";

@mixin fillStatus($statusColor) {
  path {
    &.fillColor {
      fill: $statusColor;
    }
    &.strokeColor {
      stroke: $statusColor;
    }
  }
  line {
    &.strokeColor {
      stroke: $statusColor;
    }
  }
}
.fleet {
  &.early {
    @include fillStatus($blue-200);
  }
  &.late {
    @include fillStatus($danger);
  }
  &.ontime {
    @include fillStatus($active);
  }
  &.atrisk {
    @include fillStatus($warning);
  }
  &.active {
    @include fillStatus($blue-100);
  }
  &.inactive {
    @include fillStatus($gray-450);
  }
  &.ongoing {
    @include fillStatus($blue-3);
  }
}
