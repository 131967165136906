@import "../../../../sass/Variables.scss";

.map-custom-popup-container {
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 13.5rem;
}

.traces-map {
  .trimblemaps-popup-content {
    padding: 1.35rem 0.35rem 0.35rem 0.5rem !important;
  }
}

.custom-popup-item {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  padding: 0.3rem;
  margin-right: 0.5rem;
  border-radius: $border-radius;
  &-content {
    display: flex;
    align-items: center;
  }
  &-name {
    letter-spacing: 0.03rem;
    font-weight: 600;
  }
  &-timestamp {
    font-size: 0.75rem;
  }
  &:hover {
    background-color: $gray-150;
  }
}

// Mobile styles
@media only screen and (max-width: 991.98px) {
  .map-custom-popup-container {
    width: 117%;
    margin-right: 0;
  }
  .map-custom-icon-popup {
    z-index: 99;
    width: 100%;
    top: auto !important;
    left: 0rem !important;
    bottom: 0rem !important;
    max-width: none !important;
    transform: none !important;
    background-color: $col_white;
    position: absolute !important;
    height: fit-content !important;
    .trimblemaps-control-container {
      position: relative !important;
    }
    .trimblemaps-popup-content {
      width: 100% !important;
    }
    .trimblemaps-popup-tip {
      display: none !important;
    }
  }
}
