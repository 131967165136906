@import "../../../../sass/Variables.scss";

.attachment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 7.5rem;
  background-color: $gray-200;
  border-radius: 1rem;
  margin: 0.5rem 2rem 1rem;
}

.attachment {
  &-clear {
    position: absolute;
    top: 1rem;
    margin-left: 5rem;
    cursor: pointer;
    z-index: 1;
    opacity: 0.6;
    width: 1rem;
    border-radius: 0.5rem;
    padding: 0.188rem;
    background-color: $gray-150;
    &:hover {
      opacity: 1;
    }
  }
}

.thumbnail {
  max-width: 7.5rem;
  max-height: 5.625rem;
  border-radius: 1rem;
  &-pdf {
    border: 0 !important;
  }
}
