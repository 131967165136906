@import "../../../sass/Variables.scss";

.chip {
  &-container {
    display: flex;
    flex: 0.33;
    align-items: center;
    justify-content: center;
    padding: 0rem 1rem;
    border-radius: 0.25rem;
    background-color: $col_gray_0;
    box-shadow: $col_gray_0 0rem 0.075rem;
  }
  &-text {
    display: flex;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0rem 0.4rem;
  }
}
