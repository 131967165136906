@import "../../../../sass/Variables.scss";
.attachment {
  &-link {
    display: flex;
    max-width: 100%;
    font-weight: bold;
    color: $blue-3 !important;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &-loader {
    display: inline-block;
    padding: 0 0.5rem;
    width: 1.75rem;
    height: 1.3rem;
    img {
      height: 0.75rem;
    }
    > div {
      display: inline;
    }
  }
  &-toast {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 9;
    min-width: 11rem;
  }
}
