@import "../../../sass/Variables.scss";

.conversation-threads {
  &-container {
    position: relative;
    border-top: solid 0.0063rem #e0e1e9;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column-reverse;
    animation: fadeIn 0.25s;
  }
  &-info {
    display: flex;
    align-self: center;
    justify-content: center;
  }
  &-error {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.conversation-toast {
  z-index: 9;
  position: absolute;
  left: 50%;
  bottom: 8%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 991.98px) {
  .conversation-threads {
    &-container {
      padding: 0.875rem;
      max-width: calc(100vw - 1rem);
    }
  }
}

@media only screen and (min-width: 992px) {
  .conversation-threads {
    &-container {
      padding: 1rem 6rem;
    }
  }
}
