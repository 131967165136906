@import "../../../../../sass/Variables.scss";

.zone-wrapper {
  display: flex;
  &.list {
    flex-direction: column !important;
  }
  &.card {
    flex-wrap: wrap;
    margin-top: 0.25rem;
    background-color: $gray-100;
    .zone {
      flex-direction: column;
      flex-grow: 1;
      max-width: 45%;
      padding: 1rem;
      margin: 0.125rem;
      background-color: $col-white;
    }
    .zone-label {
      font-weight: bold;
      margin-left: 0.125rem;
    }
  }
  .zone {
    display: flex;
    flex-direction: row;
    margin-left: 0.065rem;
    margin-right: 0.125rem;
    max-width: 100%;
    &-value {
      margin-left: 0.125rem;
    }
  }
}