@import "../../../sass/Variables.scss";
.form-input {
  width: 100%;
  height: 2.8rem;
  border-radius: 2rem;
  background-color: $gray-150;
  border: 0;
  padding: 0.525rem 2.25rem;
  color: $blue-100;
  font-size: 0.875rem;
  resize: none;
  &:focus {
    background-color: $gray-150 !important;
    border-bottom: none !important;
    color: $blue-100;
    line-height: 1.625rem !important;
  }
}
