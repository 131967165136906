@import "../../../sass/Variables.scss";

.new-msg-container {
  position: relative;
  font-size: 0.875rem;
  padding: 0.5rem 0rem;
  .form-input {
    padding: 0.525rem 4.75rem 0.525rem 2.25rem;
  }
  .input-with-icon-right > div {
    position: relative;
  }
}

.new-msg {
  font-size: 0.5rem;
  &-preloader {
    img {
      width: 1.5rem;
    }
  }
  &-options {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 1rem;
    align-items: center;
    &-active {
      animation: fadeIn 0.25s ease-in-out;
    }
    &-inactive {
      display: none;
    }
  }
  &-dropdown {
    margin-left: 1.25rem;
  }
}

.icon-container {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  padding: 0.33rem;
  right: 0.625rem !important;
  bottom: 0.25rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  &-attachment {
    top: 6.5rem;
  }
}

.send-icon {
  padding: 0.25rem;
}

.icon {
  &-disabled {
    fill: $gray-450;
  }
  &-enabled {
    fill: $col_blue_dark;
  }
  &-loading {
    pointer-events: none;
  }
}

.attachment-container {
  display: flex;
  position: relative;
  border: 0;
  background-color: $gray-150;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  animation: slideFromTop 0.25s;
}

.msg-toast {
  position: absolute;
  bottom: 4rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}

.form-control {
  background-clip: border-box !important;
}

.preloader {
  img {
    width: 2rem;
    opacity: 0.5;
  }
}

iframe {
  pointer-events: none !important;
}

.form-input-attachment {
  .form-input {
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
  }
  .attachment-icon {
    fill: $col_blue_dark;
  }
}

//Smaller screen size
@media only screen and (max-width: 480.9px) {
  .new-msg-options {
    padding-top: 0.2rem;
  }
  .new-msg-checkbox {
    width: 100%;
  }
  .new-msg-dropdown {
    margin-left: 0rem;
    width: 100%;
    padding-top: 0.25rem;
  }
  .dropdown {
    .btn {
      border: 0.063rem solid $col_gray_4 !important;
      border-bottom: 0.063rem solid $col_gray_4 !important;
      &:focus,
      &:focus-within {
        border-bottom: 0.063rem solid $col_gray_4 !important;
      }
    }
  }
  .toast {
    font-size: 0.85rem !important;
    min-width: 50% !important;
  }
}

//Large screen stylings.
@media only screen and (min-width: 481px) {
  .dropdown {
    .btn {
      width: 15rem !important;
    }
  }
  .dropdown-menu {
    width: 15rem !important;
  }
}
