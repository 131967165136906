@import "../../../sass/Variables.scss";

.task-details {
  &-eta {
    display: flex;
    flex-direction: column;
    min-width: 3.25rem;
    margin-right: 0.425rem;
  }
  &-content {
    display: flex;
    flex-direction: column;
    margin-left: 0.425rem;
    width: 100%;
  }
  &-address-container {
    display: flex;
    justify-content: space-between;
  }
  &-arrivalTime {
    display: flex;
    flex-direction: column;
    margin-top: 0.2rem;
    align-items: flex-end;
    font-size: 0.75rem;
    font-weight: 700;
  }
}
