@import "../../../sass/Variables.scss";

.description {
  &-bold {
    font-weight: bold;
  }
  &-italic {
    font-style: italic;
  }
  &-bold_italic {
    font-weight: bold;
    font-style: italic;
  }
  &-underline {
    text-decoration: underline;
  }
}

.tooltip .arrow::before {
  display: none;
}
