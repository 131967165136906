@import "../../../sass/Variables.scss";

.progress {
  &-container {
    padding: 0 0.5rem;
    margin-bottom: 0.3rem;
  }
  &-bar-container {
    display: flex;
    height: 1.5rem;
    flex-direction: row;
    align-items: center;
    border-radius: 0.25rem;
    position: relative;
    background-color: $col_gray_light;
  }
  &-bar-fill {
    display: grid;
    position: relative;
    height: 1.5rem;
    border-radius: 0.25rem;
    animation: progress 1.5s ease-in-out forwards;
    &-rest {
      background-color: $blue-1;
      margin: auto;
    }
  }
  &-bar-icon {
    margin: auto;
  }
  &-bar-footer {
    margin-top: 0.065rem;
  }
  &-content {
    font-size: 0.8rem;
    font-weight: bold;
    position: absolute;
    padding: 0.15rem;
    &-start {
      padding-left: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-end {
      right: 1rem;
    }
  }
  &-warning {
    color: $col_red;
  }
}
