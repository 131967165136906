@import "../../../sass/Variables.scss";

.details-view {
  display: flex;
  flex-direction: column;
  margin: 0rem;
  font-size: 0.9rem;
  font-weight: 550;
  background-color: $gray-100;
  height: 100% !important;
  padding-top: 0.5rem;
  z-index: 3;
  .error-toast-message {
    position: absolute;
    bottom: 1rem;
    width: 100%;
  }
  .details-panel {
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    height: calc(100% - 5.65rem);
    overflow: hidden;
    &-mobile {
      height: calc(100% - 5.25rem) !important;
    }
    &-dispatch {
      height: calc(100% - 8.9rem) !important;
    }
  }
}

.tab-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.063rem solid $col_gray_1 !important;
}

.details-actionBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0.85rem;
}
