@import "../../../../sass/Variables.scss";

.icon-label {
  &-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0.5rem;
  }

  &-option {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    text-align: center;
    font-size: 0.75rem;
    border-radius: $border-radius;
    justify-content: center;
    padding: 0.4rem;
    margin: 0.2rem 0.2rem;

    &.active {
      background-color: $bg-white;
    }
    &:hover {
      background-color: $gray-150;
      cursor: pointer;
    }
  }
}

.item-label {
  max-width: 5rem;
}
