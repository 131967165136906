@import "../../../../sass/Variables.scss";

.custom-datepicker {
  width: 9rem;
  &-text {
    max-width: 12rem;
    font-size: 0.9rem;
    padding-left: 0.5rem;
    color: $col_trimble_blue_dark;
  }
  .form-group {
    margin-bottom: 0rem !important;
  }
  .react-datepicker__today-button {
    border-top: 0rem;
    background-color: $col_white;
  }
  .react-datepicker__navigation--next {
    right: 1rem;
    top: 0.5rem;
  }
  .react-datepicker__navigation--previous {
    left: 1rem;
    top: 0.5rem;
  }
  .react-datepicker__header {
    background-color: $col_blue;
    color: $col_white;
    opacity: 0.98;
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: $col_white;
  }
  .react-datepicker-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: $col_white;
    color: $col_black;
    &:hover {
      background-color: $col_gray_light;
    }
  }
  .react-datepicker__navigation-icon {
    &::before {
      border-color: $col_white;
    }
  }
  .react-datepicker__navigation {
    &:hover {
      border-color: $col_white !important;
      color: $col_white !important;
    }
  }
}

.calendar-icon {
  fill: $col_trimble_blue_dark;
}

.arrow {
  &-icon {
    height: 2rem;
    min-width: 2rem;
    fill: $col_trimble_blue_dark;
    opacity: 1;
    &:hover {
      border-radius: $border-radius;
      background-color: $col_gray_0;
      transition: all 0.15s;
    }
  }
  &-disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

//Desktop styling
@media only screen and (min-width: 992px) {
  .custom-datepicker-container {
    left: 0.625rem;
  }
}

@media only screen and (max-width: 991.98px) {
  .custom-datepicker-container {
    left: 3.2rem;
  }
}
