.traces-timeline-container-mob {
  height: 100%;
  width: 100%;
  position: absolute;
  .custom-datepicker .react-datepicker-wrapper {
    position: relative !important;
  }
  .react-datepicker-popper[data-placement^="bottom"],
  .react-datepicker-popper[data-placement^="top"] {
    padding-top: 1rem !important;
    padding-right: 3rem !important;
  }
  .react-datepicker-popper {
    inset: auto !important;
    transform: translate3d(-5rem, 0rem, 0rem) !important;
  }
  .react-datepicker__triangle {
    display: none !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .traces-timeline-container-mob {
    height: calc(100vw - 3.5rem) !important;
    transform: rotate(90deg);
    width: 95vh;
  }
}

@media screen and (max-width: 576px) and (orientation: portrait) {
  .traces-timeline-container-mob {
    transform: rotate(90deg);
    height: 100vw !important;
    width: 95vh;
  }
  .tooltip-inner:has(.timeline-tooltip-wrapper) {
    transform: rotate(90deg) !important;
  }
}
