.panel-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  .header {
    flex: 0.05;
  }
  .content {
    flex: 0.9;
  }
  .footer {
    flex: 0.05;
  }
}
