.icon-with-label {
  &-container {
    display: flex;
    flex-direction: column;
    width: 3.75rem;
    justify-content: center;
    align-items: center;
  }
}

.iwl-icon {
  overflow: hidden;
  max-width: 1.75rem;
  max-height: 1.75rem;
}
