.mileage-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  img {
    max-width: 1rem;
  }
}

.mileage-value {
  margin-left: 0.33rem;
}
