@import "~@ttl/shared-react-library/src/styles/scss/variables.scss";
//included missing colors from modus
$gray-100: #f3f3f7;
$gray-150: #eaeaef;
$gray-200: #e0e1e9;
$gray-350: #aeaeb6;
$gray-400: #6a6e79;
$gray-450: #8c8b96;
$gray-50: #f9f9fb;
$bg-white: #cfe1ee;

$danger: #d52a33;
$active: #5e9331;
$warning: #ffa500;
$light_green: #4a821f;
$gray-1: #d0d0d7;
$gray-2: #24232d4d;
$blue-1: #00437b;
$blue-2: #197cb6;
$blue-3: #005f9e; //part of modus v2. Needs to be updated later
$blue-light: #217cbb;
$black: #000000;

$blue-100: #363545;
$blue-200: #009ad9;
$blue-dark: #07599b;
