.traces-toast {
  display: flex;
  position: absolute;
  bottom: 6%;
  right: 15%;
  z-index: 99;
  left: 0;
  justify-content: center;
}

//Mobile screen
@media only screen and (max-width: 991.98px) {
  .traces-toast {
    right: 0;
    justify-content: center;
  }
}
