@import "../../../sass/Variables.scss";
.progressBar__container {
  background-color: $gray-1;
  height: 0.5rem;
  border-radius: 0.313rem;
  .progressBar__status {
    border-radius: 0.313rem;
    &.danger {
      background-color: $danger;
    }
    &.warning {
      background-color: $warning;
    }
    &.normal {
      background-color: $light_green;
    }
  }
}
