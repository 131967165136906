@import "../../../sass/Variables.scss";

.msg-info {
  &-container {
    display: flex;
    width: 99%;
    align-self: center;
    padding: 0.3rem 0.875rem;
    margin: 0.35rem;
    font-size: 0.75rem;
    align-items: center;
    border-radius: 0.35rem;
    background-color: $gray-200;
    color: $gray-400;
    justify-content: space-between;
    animation: fadeIn 0.25s ease-in-out;
  }
  &-count {
    font-weight: bold;
    color: $blue-dark;
    letter-spacing: 0.01rem;
    margin-left: 1.25rem;
  }
  &-time {
    margin-left: 0.6rem;
    color: $gray-400;
    word-spacing: 0.01rem;
    font-size: 0.65rem;
    font-weight: lighter;
  }
}

.circle {
  &-wrapper {
    display: flex;
    :first-child {
      position: relative;
    }
    :not(:first-child) {
      position: absolute;
      left: 3.15rem;
    }
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border: 0.09rem solid $blue-dark;
    border-radius: 50%;
    background-color: $col_white;
  }

  &-text {
    font-size: 0.5rem;
    overflow: hidden;
    color: $blue-dark;
    font-weight: bold;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
}
