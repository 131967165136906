.vertical-line {
  border-left: 0.1rem solid rgb(1, 22, 1);
  margin-left: 0.58rem;
  margin-right: 0rem;
  margin-top: -0.2rem;
  min-height: 100%;
  max-width: 1rem;
  justify-content: space-between;
}

.timeline-icon-position {
  margin: 0rem auto;
  margin-bottom: -1rem;
  margin-right: 0rem;
}
