.trace-details {
  &-container {
    display: flex;
    flex-direction: column;
  }
  &-close-icon {
    width: 1rem;
    height: 1rem;
    align-self: flex-end;
    border-radius: 50%;
    margin: 0.25rem 0.5rem;
  }
}

.trace-type {
  font-weight: bold;
  letter-spacing: 0.03rem;
}
