@import "../../../sass/Variables.scss";

.badge-value {
  background-color: $danger;
  color: $gray-100;
  height: 1.25rem;
  min-width: 1.25rem;
  max-width: fit-content;
  border-radius: 3.125rem;
  text-align: center;
  padding: 0 0.35rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
}
