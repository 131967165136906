@import "../../../../sass/Variables.scss";

.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1.25rem;
  &-title {
    color: $blue-dark;
    margin-left: 1rem;
    font-weight: bold;
  }
  svg {
    path {
      &.fillColor {
        fill: $col_trimble_blue_dark;
      }
    }
  }
}
