.form-group-text-area {
  margin-bottom: 0rem !important;
  position: relative !important;
  textarea::-webkit-scrollbar {
    width: 0.3rem;
  }
  textarea::-webkit-scrollbar-track {
    margin: 1rem;
    background: transparent !important;
  }
}
