.driving-time-summary {
  display: flex;
  &-container {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 16rem);
    justify-content: center;
  }
  &-title {
    align-self: flex-end;
    font-style: italic;
    text-align: right;
    font-size: 0.75rem;
    width: 100%;
  }
  .row-list-container:last-child {
    margin-bottom: 0;
  }
}
