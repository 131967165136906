@import "../../../sass/Variables.scss";

.rmsc.theme {
  --rmsc-selected: $blue-light !important;
  --rmsc-h: 2rem !important;
  --rmsc-radius: 0.125rem !important;
}
.dropdown-container {
  box-shadow: none !important;
  border: none !important;
}
.rmsc .item-renderer {
  display: flex !important;
  align-items: center !important;
}
.rmsc .dropdown-content {
  z-index: 99 !important;
}

.multiSelect {
  border: 0.006rem solid $gray-200;
  border-bottom: 0.006rem solid $gray-400;
  &-label {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.4375rem;
  }
  &-placeHolder {
    font-size: 0.75rem;
    font-weight: 400;
  }
  &-value {
    display: flex;
    font-size: 0.75rem;
    max-width: 15rem !important;
  }
  &:focus,
  &:focus-within {
    border-bottom: 0.1rem solid $blue-light;
  }
  &-item {
    display: flex !important;
    align-items: center !important;
    label {
      padding: 0.375rem !important;
      cursor: pointer !important;
      margin-bottom: 0rem !important;
    }
  }
}
.apply-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.28rem;
  width: 95%;
  font-size: 0.75rem;
  border-radius: 0.09rem;
  margin-top: 0.2rem;
}
.search {
  input {
    font-size: 0.8rem !important;
  }
}

.select-item {
  cursor: pointer !important;
  margin-bottom: 0rem !important;
  margin-left: 0.35rem !important;
  padding: 0px !important;
}

.select-panel {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100% !important;
  .options {
    width: 100% !important;
  }
}
