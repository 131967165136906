@import "../../../sass/Variables.scss";

@mixin borderColor($statusColor) {
  border-left: 0.188rem solid $statusColor;
}
.accordion-trip-item {
  margin-bottom: 0.5rem;
  .accordion-trip-content {
    display: flex;
    flex-direction: row !important;
    cursor: pointer;
    padding: 1rem !important;
    margin: 1rem auto;
    margin-top: 0rem;
    margin-bottom: 0rem;
    background-color: $col_white;

    .time-window {
      font-size: 0.6rem;
      font-style: italic;
    }
  }
}
.accordion-trip-title {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  font-weight: 600;
  justify-content: space-between;
  cursor: pointer;
  background-color: $gray-150;
  padding: 0.8rem 1rem;
  &.active,
  &:hover {
    background-color: $col_white;
    transition: 0.25s;
  }
  &.ongoing-trip {
    @include borderColor($blue-dark);
  }
  &.finished-trip {
    @include borderColor($light_green);
    span {
      opacity: 0.6;
    }
  }
  &.open-trip {
    @include borderColor($blue-100);
  }
  .completed-icon {
    padding-right: 0.35rem;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.accordion-action-items {
  display: flex;
}

.trip-icon {
  margin: 0 0.8rem;
  &.active {
    .fillBtnColor {
      fill: $blue-dark;
    }
  }
  &.inactive {
    .fillBtnColor {
      fill: $blue-200;
    }
  }
}

.eta-window {
  margin-left: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.accordion-trip-content-completed {
  opacity: 0.6;
}

.highlight-task {
  animation: backgroundHighlight 1s ease-in;
  -webkit-animation: backgroundHighlight 1s ease-in;
  -moz-animation: backgroundHighlight 1s ease-in;
}
