@import "../../../sass/Variables.scss";

.new-message-thread {
  &-container {
    position: relative;
    border: 0.1rem solid $gray-200;
    border-radius: 0.5rem;
    animation: fadeIn 0.25s ease-in-out;
    padding: 0.6rem 0.875rem;
  }
  &-header {
    display: flex;
    font-size: 0.8rem;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  &-close {
    width: 0.625rem !important;
    height: 0.625rem !important;
    path {
      opacity: 1 !important;
      &.fillColor {
        fill: $black;
      }
    }
  }
}
