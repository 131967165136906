@import "../../../../sass/Variables.scss";

.form-check-input {
  cursor: pointer;
}

.map-style-layer {
  &-icon {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 4.2rem;
    margin-right: 1rem;
    cursor: pointer;
  }
  &-container {
    position: absolute;
    z-index: 1;
    top: 4.25rem;
    right: 4rem;
    width: 17.5rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
  }
  &-title {
    padding-top: 0.25rem;
    padding-left: 0.65rem;
    letter-spacing: 0.015rem;
    font-weight: 400;
    color: $gray-450;
    font-size: 0.625rem;
  }
  &-separator {
    padding: 0.125rem;
    border-bottom: 0.025rem solid $gray-350;
  }
}

.option {
  &-container {
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    cursor: pointer;
    padding: 0.25rem 0.65rem;
  }
  &-label {
    margin-left: 0.625rem;
    color: $blue-100;
  }
  &-icon-disabled {
    opacity: 0.5;
  }
}
.disabled {
  color: $gray-350;
  pointer-events: none;
}

.trimblemaps-ctrl-trpopup {
  width: 10rem !important;
}

.mob-vehicles-container {
  .map-style-layer-container,
  .map-style-layer-icon {
    top: 7rem !important;
  }
}
