@import "../../../sass/Variables.scss";
.compensation {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background-color: $gray-200;
    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  &-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-header {
    display: flex;
    justify-content: space-around;
  }
  &-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
  }
  &-details {
    display: flex;
    justify-content: space-around;
    font-weight: bold;
  }
  &-info {
    display: flex;
    justify-content: center;
    width: 50%;
  }
}
