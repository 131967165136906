@import "../../../sass/Variables.scss";

.vehicles-title-card {
  display: flex;
  flex-direction: row;
  padding: 0rem 0.25rem;
  justify-content: space-between;
  width: 100%;
  &-mobile {
    background-color: $gray-100;
    box-shadow: none;
    .badge-icon {
      margin-right: 1.2rem;
    }
  }
}

.vehicle-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.95rem;
  font-weight: bold;
  margin: 0.3rem 0rem 0.3rem 0.9rem;
  text-align: left;
  color: $blue-1;
}
.close-icon {
  cursor: pointer;
  margin-left: 0.15rem;
  border-radius: 50%;
}

.vehicle-title-icon {
  width: 1.338rem;
  height: 1.338rem;
  margin-right: 0.625rem;
  cursor: pointer;
  path {
    &.fillColor {
      fill: $blue-1;
    }
  }
}
.arrow-forward {
  margin: 0 0.5rem;
}
