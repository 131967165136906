.trace-types-multiselect {
  &-mob {
    height: 100%;
    width: 100%;
    padding: 0rem 0.5rem;
  }
  .dropdown-heading {
    height: 1.75rem !important;
  }
  .form-group {
    margin: 0.5rem;
  }
}

.trace-types-mob {
  .rmsc .options {
    max-height: calc(100vh - 14rem) !important;
  }
}

.trace-types-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 0.5rem 0.625rem;
}
