.highlight {
  font-weight: bold;
}

.text {
  width: 100%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 991.98px) {
  .text {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-align: start;
  }
}
  
//Desktop view styling
@media only screen and (min-width: 992px) {
  .text {
    white-space: nowrap;
  }
}