@import "./Variables.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    margin-left: -1rem;
  }
  100% {
    opacity: 1;
    margin-left: 0rem;
  }
}

@keyframes slideFromRight {
  0% {
    opacity: 0;
    margin-left: 1rem;
  }
  100% {
    opacity: 1;
    margin-left: 0rem;
  }
}

@keyframes slideFromBottom {
  0% {
    opacity: 0;
    margin-bottom: -1rem;
  }
  100% {
    opacity: 1;
    margin-bottom: 0rem;
  }
}

@keyframes slideFromTop {
  0% {
    margin-top: -1rem;
  }

  100% {
    margin-top: 0rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backgroundHighlight {
  50% {
    background-color: $gray-200;
  }
}
@-webkit-keyframes backgroundHighlight {
  50% {
    background-color: $gray-200;
  }
}
@-moz-keyframes backgroundHighlight {
  50% {
    background-color: $gray-200;
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
}
