@import "../../../../sass/Variables.scss";

.map-menu {
  position: absolute;
  top: 6.875rem;
  &-icon {
    z-index: 1;
    right: 0;
    margin-right: 1rem;
    cursor: pointer;
  }
  &-container {
    z-index: 2;
    right: 4rem;
    width: 10.3rem;
  }
}

.option {
  &-menu-container {
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    cursor: pointer;
    padding: 0.25rem 0.65rem;
    font-size: 0.75rem;
  }
  &-menu-label {
    margin-left: 0.02rem;
    color: $blue-100;
  }
  &-menu-icon-disabled {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 991.98px) {
  .map-menu {
    top: 9.875rem;
  }
}
