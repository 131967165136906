@import "../../../sass/Variables.scss";

.refresh-btn {
  display: flex; 
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 1.5rem;
  background-color: $col_white;
  color: $col_trimble_blue_dark;
  .refresh-icon {
    width: 0.75rem;
    height: 0.75rem;
    path {
      fill: $col_trimble_blue_dark;
    }
  }
  &:hover, &:active + .refresh-icon {
    background-color: $col_trimble_blue_dark;
    color: $col_white;
    path {
      fill: $col_white;
    }
  }
}
