@import "../../../sass/Variables.scss";

.driving-details-error {
  display: flex;
  justify-content: center;
  padding: 0 4.5rem;
  margin-top: 15%;
  align-items: center;
  &-icon {
    height: 1rem;
    width: 1rem;
    align-self: flex-start;
    display: flex;
    margin: 0.25rem 0.2rem 0rem 0rem;
  }
  &-text {
    text-align: center;
  }
}

.title {
  &-container {
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    height: 2.25rem;
  }
  &-icon {
    flex: 0.075;
    margin: 0.5rem;
  }
  &-text {
    flex: 0.85;
    font-size: 0.9rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-action-icon {
    display: flex;
    align-items: end;
    flex: 0.075;
    margin-left: 0.25rem;
  }
}

.chip-row {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
  justify-content: space-between;
  margin-top: 0.25rem;
}

.strike-through-row {
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  justify-content: space-between;
}

.divider {
  display: flex;
  align-self: center;
  height: 2.75rem;
  border-right: 0.065rem solid $col_gray_1;
}

.driving-times-footer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  font-size: 0.75rem;
  font-weight: 100;
  color: $col_gray_6;
  padding: 0.25rem 0.5rem;
  gap: 0.125rem;
}

.amplitude-footer {
  display: flex;
  font-size: 0.75rem;
  font-weight: 100;
  color: $col_gray_6;
  align-items: center;
  padding: 0.25rem 0.5rem;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: wrap;
}
