.refresh-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.85rem;
  padding: 0.5rem;
  .error-icon {
    max-width: 1rem;
    max-height: 1rem;
  }
}