@import "../../../../sass/Variables.scss";

.switch-container {
  display: flex;
  .custom-control-label {
    max-width: 3.8rem !important;
    font-size: 0.7rem !important;
    line-height: 2.8 !important;
  }
  .label {
    display: inline-block;
    max-width: 100%;
  }
}

.custom-control-input {
  &:disabled ~ .custom-control-label {
    opacity: 0.5 !important;
  }
}
