.trace-property {
  display: flex;
  flex-direction: column;
  row-gap: 0.05rem;
  &-loader {
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .refresh-icon {
    margin-left: 0.4rem;
    width: 0.813rem;
    height: 0.813rem;
  }
}
