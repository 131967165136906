.vehicle-title-block {
  width: 100%;
}
.vehicle-details {
  font-size: 0.9rem;
  margin: 1rem 0.5rem;
  margin-top: 0;
  .vehicle-property {
    margin-right: 1rem;
    margin-top: 0.75rem;
    &.temperature {
      width: 100%;
    }
    .ic-wrapper {
      margin-right: 0.375rem;
    }
  }
  &-preloader {
    height: auto !important;
    margin: 1.563rem;
  }
}
.progressBar__container {
  width: 4rem;
}
.task-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: 0px !important;
  padding-right: 1rem;
  .accordion-trip-title {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
