@import "../../../../../sass/Variables.scss";

.trace-map-container-mob {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.trace-filter-mob {
  background-color: $gray-100;
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  z-index: 99 !important;
}
.mobile-popup {
  bottom: 2.6rem !important;
  width: 100%;
  position: absolute !important;
  z-index: 2;
  font-size: 0.75rem;
  background: $col_white;
  border-radius: 0.188rem;
  display: flex;
  flex-direction: column;
  max-height: 40%;
  border-bottom: 0.01rem solid $gray-200;
  .trace-details-wrapper,
  .task-details-info-container {
    overflow-y: auto;
    padding: 0.4rem 0.98rem;
    padding-top: 0;
  }
  .timeline-icon-position {
    margin-bottom: 0rem;
  }
  .trace-type {
    white-space: normal;
  }
  .trace-details-container,
  .trace-details-content {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
  }
  .coordinate-container {
    height: 1rem;
  }
  .popup-details-container {
    display: flex;
    flex-direction: column;
  }
  .traceview-info {
    &-container {
      display: flex;
      flex-direction: column;
      padding-left: 0.5rem;
    }
    &-close-icon {
      position: relative;
      width: 1rem;
      height: 1rem;
      align-self: flex-end;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
}
.task-details-close-icon {
  width: 1rem;
  height: 1rem;
  align-self: flex-end;
  border-radius: 50%;
  margin: 0.25rem 0.5rem;
}

.timeline-btn-mob {
  img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  bottom: 5rem;
  padding: 0.5rem 0.8rem;
  background-color: $col_white;
  color: $col_trimble_blue_dark;
  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: 0.02rem;
  border-radius: 1rem;
  box-shadow: $col_gray_0 0rem 0.075rem;
}
@media screen and (min-width: 576px) and (max-width: 991.98px) {
  .mobile-popup {
    .trace-details-content,
    .trace-property {
      display: flex;
      flex-direction: row;
      column-gap: 4.5rem;
      flex-wrap: wrap;
    }
    .trace-property-details,
    .trace-details-content > * {
      width: 42%;
    }
  }
}
