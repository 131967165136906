@import "../../../sass/Variables.scss";

.autocomplete {
  &-search {
    margin: 1rem 0.85rem 0.5rem;
  }
  &-list-item {
    display: flex;
    align-items: center;
  }
  &-info {
    display: flex;
    font-size: 0.85rem;
    margin: 1.5rem;
    justify-content: center;
  }
  &-text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &-subtitle {
    color: $gray-400;
  }
}

.preloader {
  img {
    width: 2rem;
  }
}

.tooltip-inner {
  min-width: 18rem;
  border: 0.063rem $gray-150 solid;
  background-color: $col_white;
  color: $col_black;
  padding: 0 !important;
  box-shadow: $gray-350 0rem 0.2rem 0.5rem;
}

// Mobile view styling
@media only screen and (max-width: 991.98px) {
  .autocomplete {
    &-container {
      display: flex;
      flex-direction: column;
    }
  }
}

//Desktop view styling
@media only screen and (min-width: 992px) {
  .autocomplete {
    &-container {
      display: flex;
      flex-direction: column;
    }
  }
}
