@use "./Keyframes";
@import "./Variables.scss";
.override-push-panel {
  height: calc(100% - 3rem);
  .app-content-panel {
    overflow: hidden;
  }
}
.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}
.cursor-pointer {
  cursor: pointer !important;
}
.SplitPane {
  align-items: center;
  .Pane {
    width: 100%;
    background: $col_white;
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    height: 100px;
    &.Pane1 {
      max-height: 98%;
    }
    .display-section {
      font-size: 0.75rem;
    }
    .card-view {
      overflow-y: auto;
      height: calc(100% - 2.125rem);
    }
    &.Pane2 {
      border-top-right-radius: 3%;
      border-top-left-radius: 3%;
    }
  }
  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 15px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 20%;
  }
}

.top-bar {
  height: 3rem;
}
.break-word {
  word-break: break-word;
}

.linebreak {
  white-space: pre-line;
}

.row-gap {
  row-gap: 0.125rem;
}
.loader {
  &-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    justify-content: center;
    background-color: $col_gray_1;
    z-index: 2;
  }
  &-message {
    color: $black;
  }
}

.paper {
  &-container {
    padding: 0.625rem;
    margin: 0.75rem 0.65rem;
    border-radius: 0.25rem;
    background-color: $col_white;
    box-shadow: $col_gray_0 0rem 0.075rem;
  }
  &-title {
    display: flex;
    font-size: 0.8rem;
    font-weight: normal !important;
    padding: 0 0.25rem 0 0.05rem;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }
}

.block-container {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.75rem !important;
  padding: 0.875rem 2rem !important;
}

.nav-tabs {
  flex: 1;
  border-bottom: none !important;
}

.tooltip-inner:has(.tooltip-info) {
  min-width: 0;
  padding: 0.25rem !important;
}
