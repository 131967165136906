@import "../../../../sass/Variables.scss";

.trace-view {
  &-container {
    position: relative;
    height: 2rem;
    width: 2rem;
    background-color: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &-menu-card {
    position: absolute;
    width: 13rem;
    left: 2.85rem;
    top: 0rem;
  }

  &-menu-icon {
    top: 1rem;
    padding: 0.4rem;
  }
}

.beta-img {
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  top: -0.05rem;
  right: -0.05rem;
}

@media only screen and (max-width: 991.98px) {
  .trace-view {
    &-menu-card {
      left: unset;
      right: 3rem;
    }
  }
}
