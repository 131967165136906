@import "../../../../sass/Variables.scss";
.trace-task-switch {
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: $col_white;
  padding: 0rem 0.5rem;
  margin-right: 0.85rem;
}

//Mobile screen
@media only screen and (max-width: 991.98px) {
  .trace-task-switch {
    position: absolute;
    right: 2rem;
    left: unset;
    top: 0;
  }
}
