@import "../../../../sass/Variables.scss";

.two-col-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.col-3,
.col-2,
.col-4,
.col-6,
.col-8,
.col-9,
.col-10 {
  padding: 0 !important;
}
